#ms-alert-bart-link {
  display: block;
  margin-top: 3px;
  margin-bottom: 3px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.5px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
